<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Пациенты
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon>
        Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon>
        Архивные
      </v-chip>
    </v-chip-group>
    <object-grid-advanced
      title="Пациенты"
      :value="patients_with_responsible_info"
      :field-defs="[
        { text: 'Пациент', value: 'fio', width: '41%' },
        { text: 'Компания', value: 'company_name', width: '8%' },
        { text: 'Последний вход', value: 'last_entry', width: '8%' },
        { text: 'Ответственный', value: 'user_full_name', width: '31%' },
      ]"
      :hide-toolbar="false"
      :show-actions="{
        edit: !selection,
      }"
      @itemSelected="(v) => (this.selected = v)"
      @doEditItem="
        (item) =>
          $router.push({
            name: 'manager-patient',
            params: {
              patient_id: item.id,
              show_company: item.company_name,
              no_editing: true,
            },
          })
      "
      instance-id="manager_patientSearch"
    />
  </div>
</template>

<script>
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ManagerPatientsView",
  components: { ObjectGridAdvanced },
  data() {
    return {
      selected: [],
      selection: 0,
    };
  },
  computed: {
    ...mapGetters("companies", ["companies"]),
    ...mapGetters("patients", ["patients"]),
    ...mapGetters("users", ["users"]),

    title() {
      return this.modalFormComponent?.title;
    },
    desc() {
      return this.modalFormComponent?.desc;
    },
    loadedPatients() {
      return this.patients.map((patient) => {
        const user = this.users.find((user) => user.patient_id === patient.id);
        const responsible_user = this.users.find(
          (user) => user.id === patient.user_id
        );
        return { ...patient, responsible_user, user };
      });
    },
    patients_with_responsible_info() {
      return this.loadedPatients.map((patient) => {
        const user_full_name =
          patient.responsible_user && patient.responsible_user.full_name
            ? patient.responsible_user.full_name
            : "";

        const company = this.companies.find((c) => c.id === patient.company_id);
        const company_name = company ? company.name : "";

        const last_entry = patient.user?.last_entry
          ? new Date(patient.user?.last_entry).toLocaleString()
          : "";

        return {
          ...patient,
          company_name,
          user_full_name: user_full_name,
          last_entry,
        };
      });
    },
  },
  methods: {
    ...mapActions("patients", ["request_patients", "update_patient"]),
    ...mapActions("companies", ["request_companies"]),
    ...mapActions("users", ["request_users"]),
    ...mapActions("modals", ["showModalForm"]),
    switchActiveOrArchive() {
      // check on next tick
      this.$nextTick(() => {
        this.request_patients(this.selection ? "archive=1" : "");
      });
    },
  },
  beforeMount() {
    this.request_patients();
    this.request_users();
    this.request_companies();
  },
};
</script>

<style scoped></style>
