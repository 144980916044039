/**
 * Constants map for database roles and their corresponding values for the GUI
 *
 * @type {{"company-owner": string, manager: string, "company-user": string, admin: string}}
 */
export const ROLE_TRANSLATIONS = {
  admin: "Администратор",
  manager: "Ответственный менеджер",
  "company-user": "Сотрудник компании",
  "company-owner": "Представитель компании",
  "tech-user": "Техническая учетная запись",
};

/**
 * All active roles in app
 *
 * @type {string[]}
 */
export const ALL_ROLES = [
  "admin",
  "manager",
  "company-user",
  "company-owner",
  "patient",
  "tech-user",
];

/**
 * This is categories tree. Now it's used in AdminProductCategoriesView and ManagerProductCategoriesView
 *
 * @type {json}
 */
export const CATEGORIES = [
  {
    id: 1,
    name: "ДЕТСКОЕ ЗДОРОВЬЕ",
    children: [
      {
        id: 9,
        parentId: 1,
        name: "ОБЩИЕ РАЗВИВАЮЩИЕ ПРОГРАММЫ",
        children: [],
      },
      {
        id: 10,
        parentId: 1,
        name: "ЛОГОПЕДИЧЕСКАЯ КОРРЕКЦИЯ РЕЧИ",
        children: [],
      },
      {
        id: 11,
        parentId: 1,
        name: "СПЕЦИАЛЬНЫЕ ПРОГРАММЫ ПРИ НАРУШЕНИЯХ РАЗВИТИЯ",
        children: [
          {
            id: 14,
            parentId: 11,
            name: "СДВГ",
            children: [],
          },
          {
            id: 15,
            parentId: 11,
            name: "РАС",
            children: [],
          },
          {
            id: 16,
            parentId: 11,
            name: "ЗПР",
            children: [],
          },
          {
            id: 17,
            parentId: 11,
            name: "ДЦП",
            children: [],
          },
          {
            id: 18,
            parentId: 11,
            name: "СИНДРОМ ДАУНА",
            children: [],
          },
        ],
      },
      {
        id: 12,
        parentId: 1,
        name: "НЕВРОЛОГИЧЕСКИЕ НАРУШЕНИЯ",
        children: [
          {
            id: 19,
            parentId: 12,
            name: "НАРУШЕНИЕ МОТОРИКИ И КООРДИНАЦИИ",
            children: [],
          },
          {
            id: 20,
            parentId: 12,
            name: "ТИКИ И САМОПРОИЗВОЛЬНЫЕ ДВИЖЕНИЯ (ТРЕМОРЫ)",
            children: [],
          },
          {
            id: 21,
            parentId: 12,
            name: "ЧЕРЕПНО-МОЗГОВЫЕ ТРАВМЫ, СОТРЯСЕНИЯ МОЗГА, ОПЕРАТИВНЫЕ ВМЕШАТЕЛЬСТВА",
            children: [],
          },
        ],
      },
      {
        id: 13,
        parentId: 1,
        name: "ПОМОЩЬ РОДИТЕЛЯМ",
        children: [],
      },
    ],
  },
  {
    id: 2,
    name: "ПОСТКОВИДНАЯ РЕАБИЛИТАЦИЯ",
    children: [
      {
        id: 22,
        parentId: 2,
        name: "ВОССТАНОВЛЕНИЕ ОРГАНИЗМА ПОСЛЕ COVID",
        children: [],
      },
      {
        id: 23,
        parentId: 2,
        name: "ПРОФИЛАКТИКА COVID",
        children: [],
      },
      {
        id: 24,
        parentId: 2,
        name: "ВОССТАНОВЛЕНИЕ ИММУНИТЕТА. ПРОФИЛАКТИКА ВИРУСНЫХ ЗАБОЛЕВАНИЙ",
        children: [],
      },
    ],
  },
  {
    id: 3,
    name: "ЖЕНСКОЕ ЗДОРОВЬЕ",
    children: [
      {
        id: 25,
        parentId: 3,
        name: "ВОССТАНОВЛЕНИЕ ГОРМОНАЛЬНОГО ФОНА И РЕПРОДУКТИВНЫХ ФУНКЦИЙ",
        children: [],
      },
      {
        id: 26,
        parentId: 3,
        name: "СОХРАНЕНИЕ БЕРЕМЕННОСТИ И ПРОФИЛАКТИКА БОЛЕЙ ПРИ РОДАХ",
        children: [],
      },
      {
        id: 27,
        parentId: 3,
        name: "ВОССТАНОВЛЕНИЕ ПОСЛЕ РОДОВ",
        children: [],
      },
      {
        id: 28,
        parentId: 3,
        name: "КЛИМАКТЕРИЧЕСКИЙ ПЕРИОД ГОРМОНАЛЬНЫЙ БАЛАНС",
        children: [],
      },
    ],
  },
  {
    id: 4,
    name: "НЕВРОЛОГИЧЕСКИЕ НАРУШЕНИЯ",
    children: [
      {
        id: 29,
        parentId: 4,
        name: "ПРОФИЛАКТИКА И РЕАБИЛИТАЦИЯ ЦЕРЕБРОВАСКУЛЯРНОЙ БОЛЕЗНИ (ЦВБ)",
        children: [],
      },
      {
        id: 30,
        parentId: 4,
        name: "ПРОФИЛАКТИКА И РЕАБИЛИТАЦИЯ ОНМК",
        children: [
          {
            id: 34,
            parentId: 30,
            name: "ТРАНЗИТОРНАЯ ИШЕМИЧЕСКАЯ АТАКА (ТИА)",
            children: [],
          },
          {
            id: 35,
            parentId: 30,
            name: "ИШЕМИЧЕСКИЙ ИНСУЛЬТ",
            children: [],
          },
          {
            id: 36,
            parentId: 30,
            name: "ГЕМОРРАГИЧЕСКИЙ ИНСУЛЬТ",
            children: [],
          },
        ],
      },
      {
        id: 31,
        parentId: 4,
        name: "МИГРЕНИ И ГОЛОВНЫЕ БОЛИ",
        children: [],
      },
      {
        id: 32,
        parentId: 4,
        name: "РЕАБИЛИТАЦИЯ ЧЕРЕПНО-МОЗГОВЫЕ ТРАВМЫ, СОТРЯСЕНИЯ МОЗГА, ОПЕРАТИВНЫЕ ВМЕШАТЕЛЬСТВА",
        children: [],
      },
      {
        id: 33,
        parentId: 4,
        name: "ПСИХОЛОГИЧЕСКИЕ И НЕВРОЛОГИЧЕСКИЕ ДИСФУНКЦИИ",
        children: [
          {
            id: 37,
            parentId: 33,
            name: "СТРЕССЫ, НЕВРОТИЧЕСКИЕ И ТРЕВОЖНЫЕ РАССТРОЙСТВА",
            children: [],
          },
          {
            id: 38,
            parentId: 33,
            name: "ДЕПРЕССИИ",
            children: [],
          },
          {
            id: 39,
            parentId: 33,
            name: " НАРУШЕНИЕ СНА",
            children: [],
          },
          {
            id: 40,
            parentId: 33,
            name: "НЕПРОИЗВОЛЬНАЯ ДВИГАТЕЛЬНАЯ АКТИВНОСТЬ, ТИКИ. ТРЕМОРЫ",
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "НЕЙРОДЕГЕНЕРАТИВНЫЕ НАРУШЕНИЯ",
    children: [
      {
        id: 41,
        parentId: 5,
        name: "ПРОФИЛАКТИКА И ВОССТАНОВЛЕНИЕ БОЛЕЗНЬ ПАРКИНСОНА",
        children: [],
      },
      {
        id: 42,
        parentId: 5,
        name: "ПРОФИЛАКТИКА И ВОССТАНОВЛЕНИЕ БОЛЕЗНЬ АЛЬЦГЕЙМЕРА",
        children: [],
      },
      {
        id: 43,
        parentId: 5,
        name: "ПРОФИЛАКТИКА И ВОССТАНОВЛЕНИЕ ВОЗРАСТНАЯ ДЕМЕНЦИЯ",
        children: [],
      },
      {
        id: 44,
        parentId: 5,
        name: "ПРОФИЛАКТИКА И ПОДДЕРЖКА РАССЕЯНЫЙ СКЛЕРОЗ",
        children: [],
      },
    ],
  },
  {
    id: 6,
    name: "ЭНДОКРИНОЛОГИЧЕСКИЕ НАРУШЕНИЯ",
    children: [
      {
        id: 45,
        parentId: 6,
        name: "ВОССТАНОВЛЕНИЕ И ПОДДЕРЖКА ФУНКЦИЙ ЩИТОВИДНОЙ ЖЕЛЕЗЫ",
        children: [],
      },
      {
        id: 46,
        parentId: 6,
        name: "ВОССТАНОВЛЕНИЕ И ПОДДЕРЖКА ПРИ САХАРНОМ ДИАБЕТЕ ПО 1 И 2 ТИПУ",
        children: [],
      },
      {
        id: 47,
        parentId: 6,
        name: "ВОССТАНОВЛЕНИЕ И ПОДДЕРЖКА  ФУНКЦИЙ НАДПОЧЕЧНИКОВ",
        children: [],
      },
    ],
  },
  {
    id: 7,
    name: "НАРУШЕНИЯ СЕРДЕЧНО-СОСУДИСТОЙ СИСТЕМЫ",
    children: [
      {
        id: 48,
        parentId: 7,
        name: "ВОССТАНОВЛЕНИЕ И ПРОФИЛАКТИКА ПРИ ГИПЕРТОНИИ",
        children: [],
      },
      {
        id: 49,
        parentId: 7,
        name: "ПРОФИЛАКТИКА И ВОССТАНОВЛЕНИЕ  ПОСЛЕ ИНФАРКТА",
        children: [],
      },
      {
        id: 50,
        parentId: 7,
        name: "ПРОФИЛАКТИКА И ВОССТАНОВЛЕНИЕ  ПРИ АРИТМИИ",
        children: [],
      },
      {
        id: 51,
        parentId: 7,
        name: "РЕАБИЛИТАЦИЯ ПОСЛЕ ОПЕРАТИВНОГО ВМЕШАТЕЛЬСТВА",
        children: [],
      },
    ],
  },
  {
    id: 8,
    name: "ЗДОРОВЬЕ ПОЖИЛЫХ ЛЮДЕЙ",
    children: [
      {
        id: 52,
        parentId: 8,
        name: "ПРОФИЛАКТИКА И ВОССТАНОВЛЕНИЕ ПРИ ВОЗРАСТНОЙ ДЕМЕНЦИИ",
        children: [],
      },
      {
        id: 53,
        parentId: 8,
        name: "ПРОФИЛАКТИКА И ВОССТАНОВЛЕНИЕ  ПРИ БОЛЕЗНИ ПАРКИНСОНА",
        children: [],
      },
      {
        id: 54,
        parentId: 8,
        name: "ПРОФИЛАКТИКА И ВОССТАНОВЛЕНИЕ  ПРИ БОЛЕЗНИ АЛЬЦГЕЙМЕРА",
        children: [],
      },
      {
        id: 55,
        parentId: 8,
        name: "ПСИХОФИЗИОЛОГИЧЕСКАЯ РЕАБИЛИТАЦИЯ И ПРОФИЛАКТИКА ВОЗРАСТНЫХ ЗАБОЛЕВАНИЙ",
        children: [],
      },
    ],
  },
  {
    id: 56,
    name: "ПОСТТРАВМАТИЧЕСКОЕ СТРЕССОВОЕ РАССТРОЙСТВО",
    children: [
      {
        id: 57,
        parentId: 56,
        name: "ДЕТСКОЕ (ПТСР)",
        children: [],
      },
      {
        id: 58,
        parentId: 56,
        name: "ВЗРОСЛОЕ (ПТСР)",
        children: [],
      },
    ],
  },
  {
    id: 59,
    name: "НЕЙРОПСИХОЛОГИЧЕСКОЕ СОПРОВОЖДЕНИЕ ПРИ ОНКОЛОГИЧЕСКИХ ЗАБОЛЕВАНИЯХ",
    children: [
      {
        id: 60,
        parentId: 59,
        name: "ПОДДЕРЖКА ПРИ ЛЕЧЕНИИ ОНКОЛОГИЧЕСКИХ ЗАБОЛЕВАНИЙ",
        children: [],
      },
      {
        id: 61,
        parentId: 59,
        name: "РЕАБИЛИТАЦИЯ ПРИ ВОССТАНОВЛЕНИИ ПОСЛЕ ОНКОЛОГИЧЕСКИХ ЗАБОЛЕВАНИЙ",
        children: [],
      },
    ],
  },
  {
    id: 62,
    name: "ТЕСТОВЫЕ СЕССИИ",
    children: [
      {
        id: 63,
        parentId: 62,
        name: "ДЛЯ ВЗРОСЛЫХ",
        children: [],
      },
      {
        id: 64,
        parentId: 62,
        name: "ДЕТСКИЕ",
        children: [],
      },
    ],
  },
];
