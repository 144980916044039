<template>
  <v-container>
    <v-card>
      <v-card-title> Личная информация </v-card-title>
      <v-card-text>
        <property-form
          :value="user"
          :fields="
            [
              'email',
              'first_name',
              'last_name',
              'referral_code',
              'phone',
              'inn',
              'ogrn',
            ].reduce((a, v) => {
              a[v] = { visible: true };
              return a;
            }, {})
          "
          @input="doUpdateUser"
        />
      </v-card-text>
    </v-card>
    <v-card class="my-5">
      <v-card-title> Глобальные настройки </v-card-title>
      <v-card-text>
        <property-form
          :value="propertiesValues"
          :fields="propertiesFields"
          @input="saveProperties"
          :simulate-reactivity="true"
        />
      </v-card-text>
    </v-card>
    <object-grid-advanced
      class="mt-5"
      :value="categories"
      :field-defs="[
        { text: 'Категория', value: 'name', width: '40%' },
        {
          text: 'Стоимость назначения',
          value: 'session_price',
          width: '38%',
        },
      ]"
      :show-actions="{
        edit: true,
      }"
      @doEditItem="editCategoryPrice"
      :instanceId="`preferenceSearch_${selection ? 'archive' : 'active'}`"
    />
  </v-container>
</template>

<script>
import PropertyForm from "@/components/PropertyForm.vue";
import { mapActions, mapGetters } from "vuex";
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import CategoryForm from "@/components/CategoryForm.vue";

export default {
  name: "AdminPreferenceView",
  components: { PropertyForm, ObjectGridAdvanced },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("categories", ["categories"]),
    ...mapGetters(["configProperty"]),

    propertiesValues() {
      return {
        patientSessionTimeout: this.configProperty("patientSessionTimeout"),
      };
    },

    propertiesFields() {
      return {
        patientSessionTimeout: {
          label: "Тайм-аут для пациентов",
          visible: this.user?.role === "admin",
          type: "number",
          rules: [
            (v) => /^[0-9]{1,5}$/.test(v) || "Целое число, не меньше нуля",
          ],
        },
      };
    },
  },
  methods: {
    ...mapActions(["doRequestConfigProperty"]),
    ...mapActions(["doSetConfigProperty"]),
    ...mapActions(["doUpdateUser"]),
    ...mapActions("categories", ["request_categories", "update_category"]),
    ...mapActions("modals", ["showModalForm"]),

    saveProperties(items) {
      for (let key in items)
        this.doSetConfigProperty({ name: key, value: items[key] });
    },
    editCategoryPrice(category) {
      this.showModalForm({
        component: CategoryForm,
        title: "Редактирование стоимости назначения",
        desc: ``,
        session_price: category?.session_price || 0,
      }).then((payload) => {
        this.update_category({
          ...category,
          session_price: Number(payload.session_price),
        });
      });
    },
  },
  beforeMount() {
    this.request_categories();
    this.doRequestConfigProperty("patientSessionTimeout");
  },
};
</script>

<style scoped></style>
