var render = function render(){var _vm=this,_c=_vm._self._c;return _c('property-form',{attrs:{"value":_vm.value || {
      name: '',
      phone: '',
      inn: '',
      ogrn: '',
      email: '',
      trial: '',
      balance: '',
      city: '',
      trial_end_time: '',
      max_patients: '5',
    },"fields":_vm.fieldDefs},on:{"input":_vm.doSave}})
}
var staticRenderFns = []

export { render, staticRenderFns }