<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Сотрудники
      <v-btn
        v-if="user.role === 'company-owner'"
        color="#d86568"
        large
        rounded
        class="pa-5 px-sm-10 white--text"
        @click="addNewUser"
      >
        <v-icon left>mdi-plus</v-icon>
        Добавить
      </v-btn>
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
      v-if="this.user.role === 'company-owner'"
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon> Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon> Архивные
      </v-chip>
    </v-chip-group>
    <object-grid-advanced
      :value="
        users.filter(
          (user) => user.role !== 'company-owner' && user.role !== 'patient'
        )
      "
      title="Сотрудники"
      :field-defs="[
        {
          text: 'Сотрудник',
          value: 'full_name',
          width: '33%',
        },
        { text: 'E-mail', value: 'email', width: '33%' },
        {
          text: 'Количество пациентов',
          value: 'patients_count',
          width: '22%',
        },
      ]"
      :show-actions="{
        edit: user.role === 'company-owner' && !selection,
        delete: user.role === 'company-owner' && !selection,
        restore: user.role === 'company-owner' && selection,
      }"
      @doEditItem="editUser"
      @doDeleteItem="deleteItem"
      @doRestoreItem="restoreItem"
      instance-id="company_usersSearch"
    />
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import UserForm from "@/components/UserForm.vue";
import PromptForm from "@/components/PromptForm.vue";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";

export default {
  name: "CompanyUsersView",
  components: { ErrorMessageWrapper, ObjectGridAdvanced },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("patients", ["patients"]),
    ...mapGetters("modals", ["modalFormComponent"]),
  },
  beforeUpdate() {
    this.users.forEach((user) => {
      const patients = this.patients.filter((patient) => {
        return patient.user_id === user.id;
      });
      user.patients_count = patients.length;
    });
  },
  data() {
    return {
      selection: 0,
    };
  },
  methods: {
    ...mapActions("users", [
      "request_users",
      "create_user",
      "update_user",
      "delete_user",
    ]),
    ...mapActions("patients", ["request_patients"]),
    ...mapActions("modals", ["showModalForm"]),

    addNewUser() {
      // костыль, если перед этим было вызвано showModalForm с UserForm
      // то в this.modalFormComponent.value будет лежать value
      // с значениями ранее открытого пользователя
      // поэтому тут value обнуляется
      // TODO: исправить
      if (this.modalFormComponent) this.modalFormComponent.value = undefined;
      this.$router.push({ name: "company-new-user" });
    },
    editUser(user) {
      this.showModalForm({
        component: UserForm,
        title: `Сотрудник ${user.id}`,
        desc: ``,
        value: user,
      }).then((payload) => {
        this.update_user(payload).then(() => {
          // check on next tick
          this.$nextTick(() => {
            this.request_users(this.selection ? "archive=1" : "");
          });
        });
      });
    },
    deleteItem(item) {
      if (item.id === this.user.id) {
        // Попытка удалить самого себя.
        this.$refs.error_wrapper.showErrorMessage(
          "Ошибка",
          `Вы не можете удалить свой аккаунт.`
        );
        return;
      }
      const user_patients = this.patients.filter((patient) => {
        return patient.user_id === item.id;
      });
      if (user_patients.length != 0) {
        // За данным пользователем закреплены пациенты
        this.$refs.error_wrapper.showErrorMessage(
          "Ошибка",
          `За данным сотрудником закреплены пациенты (${user_patients.length}).`
        );
        return;
      }
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены что хотите удалить сотрудника ${item.email}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        this.delete_user(item).then(() => {
          this.request_users(this.selection ? "archive=1" : "");
        });
      });
    },
    switchActiveOrArchive() {
      // check on next tick
      this.$nextTick(() => {
        this.request_users(this.selection ? "archive=1" : "");
      });
    },
    restoreItem(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены, что хотите восстановить пользователя ${item.full_name}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        const updatedUser = { ...item, active: true };
        this.update_user(updatedUser).then(() => {
          this.request_users("archive=1");
        });
      });
    },
  },
  beforeMount() {
    this.request_patients();
    this.request_users();
  },
};
</script>

<style scoped lang="scss"></style>
