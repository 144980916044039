<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Карточка пациента
      <router-link
        :to="{
          name: this.patients_view[user.role],
        }"
        class="text-body-1 text-sm-h6"
        >Все пациенты</router-link
      >
    </div>
    <div class="text-h6" v-if="show_company">Компания: {{ show_company }}</div>
    <v-card v-if="patient" elevation="0" class="transparent">
      <v-card>
        <v-card-subtitle>
          Последнее обновление:
          {{ new Date(patient.updatedAt).toLocaleDateString() }}
        </v-card-subtitle>
        <v-card-text>
          <property-form
            v-if="patient.id"
            :value="patient"
            :fields="fields"
            @input="doSave"
            :no_editing="no_editing"
          />
        </v-card-text>
      </v-card>
      <v-card
        v-if="user.role !== 'manager'"
        class="my-5 d-flex justify-space-between align-center pa-4"
      >
        <div class="text-h6">Ответственный: {{ responsible_user }}</div>
        <v-btn
          class="white--text"
          style="background-color: #d86568"
          elevation="2"
          icon
          @click="changeResponsible = true"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card>
      <v-card
        v-if="this.editPatientAccount || this.patient.user_id === this.user.id"
        class="my-5 d-flex justify-space-between align-center pa-4"
      >
        <v-container>
          <v-row class="justify-space-between">
            <div class="text-body-1 text-sm-h5 black--text">
              {{
                `Заведен аккаунт: ${
                  this.patient_account?.email
                    ? this.patient_account.email
                    : "Нет"
                }`
              }}
            </div>
            <div v-if="this.patient_account?.email">
              <v-btn
                v-if="user.role === 'admin'"
                class="white--text"
                rounded
                elevation="0"
                style="background-color: #d86568"
                @click="doChangeEmail"
                >Изменить почту</v-btn
              >
              <v-btn
                class="white--text ml-2"
                rounded
                elevation="0"
                style="background-color: #d86568"
                @click="doChangePassword"
                >Изменить пароль</v-btn
              >
            </div>
            <v-btn
              v-if="
                !this.patient_account?.email &&
                (user.role === 'admin' ||
                  user.role === 'company-owner' ||
                  user.role === 'company-user') &&
                !canCreateAccount
              "
              class="white--text"
              style="background-color: #d86568"
              elevation="2"
              @click="canCreateAccount = true"
              >Создать</v-btn
            >
          </v-row>
          <v-row v-if="canCreateAccount">
            <v-card-text>
              <property-form
                :value="{}"
                :fields="account_fields"
                @input="doSaveAccount"
                :no_editing="no_editing"
              />
            </v-card-text>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="my-5">
        <v-card-text>
          <v-card-actions class="px-0">
            <div class="views-history text-body-1 text-sm-h5 black--text">
              Сессии
            </div>
            <v-spacer></v-spacer>
            <v-card elevation="0" class="d-flex align-center">
              <v-card-text
                class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
              >
                4 сессии
              </v-card-text>
            </v-card>
            <v-spacer></v-spacer>
            <v-card elevation="0" class="d-flex align-center">
              <v-card-text
                class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
              >
                {{ (patient.visits || []).length }} визитов
              </v-card-text>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn
              v-if="
                user.role === 'company-owner' || user.role === 'company-user'
              "
              style="background-color: #d86568"
              elevation="2"
              icon
              @click="doNewVisit"
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
          <object-grid
            :content_type="content_type"
            :value="patient.visits || []"
            :hide-toolbar="true"
            :show-actions="{
              edit: this.user.role !== 'manager' ? true : false,
              url: this.user.role !== 'manager' ? true : false,
              delete: this.user.role !== 'manager' ? true : false,
              refund: this.user.role !== 'manager' ? true : false,
            }"
            :field-defs="[
              { text: 'Сессия', value: 'product_name' },
              { text: 'Просмотрено', value: 'viewProgressFormatted' },
              { text: 'Дата назначения', value: 'date' },
              { text: 'Назначивший', value: 'prescriber' },
            ]"
            @doEditItem="showSessionDialog"
            @generateUrl="generateUrl"
            @doDeleteItem="doDeleteItem"
            @doRefundItem="doRefundItem"
            class="mb-7"
          />

          <div class="views-history text-body-1 text-sm-h5 black--text">
            История сессий
          </div>
          <object-grid
            :content_type="content_type"
            :value="patient.viewed || []"
            :hide-toolbar="true"
            :show-actions="{
              edit: false,
              url: false,
              delete: false,
            }"
            :field-defs="[
              { text: 'Сессия', value: 'product_name' },
              { text: 'Дата назначения', value: 'date' },
              { text: 'Назначивший', value: 'prescriber' },
              { text: 'Статус', value: 'complete_reason' },
              { text: 'Просмотрено', value: 'viewProgressFormatted' },
              { text: 'Дата окончания', value: 'complete_date' },
            ]"
            @doEditItem="showSessionDialog"
            @generateUrl="generateUrl"
            @doDeleteItem="doDeleteItem"
          />
        </v-card-text>
      </v-card>
    </v-card>
    <v-dialog
      v-model="changeResponsible"
      :max-width="$vuetify.breakpoint.smAndUp ? '50%' : '100%'"
      ><v-card color="#f0f8f6" class="overflow-hidden rounded-xl">
        <div class="text-right ma-3">
          <v-btn icon @click="changeResponsible = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row justify="center" align="center" class="pb-5">
          <v-col cols="16" class="text-center text-h4">
            <div>Изменить ответственного</div>
          </v-col>
        </v-row>
        <v-card-text>
          <object-grid
            :value="
              users.filter(
                (user) =>
                  user.role === 'company-user' || user.role === 'company-owner'
              )
            "
            :field-defs="[
              {
                text: 'Выберите пользователя',
                value: 'full_name',
              },
            ]"
            :hide-toolbar="true"
            :selectable="true"
            :single-select="true"
            @itemSelected="(v) => (this.selected = v)"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="white--text"
            style="
              background-color: #d86568;
              width: 150px;
              height: 50px;
              border-radius: 50px;
            "
            @click="changeResponsibleUser()"
            :disabled="!selected"
          >
            Назначить
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-snackbar
      class="snackbar"
      v-model="snackbar"
      :timeout="timeout"
      color="primary"
      outlined
    >
      {{ snackbarText }}
    </v-snackbar>
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ObjectGrid from "@/components/ObjectGrid";
import PropertyForm from "@/components/PropertyForm";
import { VTextarea } from "vuetify/lib/components";
import SelectProductsForm from "@/components/SelectProductsForm";
import SessionForm from "@/components/SessionForm";
import PromptForm from "@/components/PromptForm";
import ApiService from "@/api/ApiService";
import { v4 as uuidv4 } from "uuid";
import PasswordResetForm from "../components/PasswordResetForm.vue";
import EmailResetForm from "../components/EmailResetForm.vue";

import CryptoJS from "crypto-js";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";

export default {
  name: "PatientView",
  components: { ErrorMessageWrapper, PropertyForm, ObjectGrid },
  props: {
    patient_id: String,
    show_company: String,
    no_editing: Boolean,
  },
  data() {
    return {
      selection: 0,
      content_type: "products",
      changeResponsible: false,
      selected: undefined,
      snackbar: false,
      snackbarText: "",
      timeout: 3000,
      toggleForRefresh: false,
      haveRefundableVisits: false,
      fioRules: [(v) => !!v || "Обязательное поле"],
      emailRules: [
        (v) => !v || /.+@.+\..+/.test(v) || "Пример: abc@de.fg",
        (v) => !v || !v.includes(" ") || "Пробелы запрещены",
      ],
      passwordRules: [
        (v) =>
          !v ||
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/.test(v) ||
          "Минимум 8 символов, цифры, буквы, знаки",
        (v) => !v || !v.includes(" ") || "Пробелы запрещены",
      ],
      notEmptyRules: [(v) => !!v || "Обязательное поле"],
      canCreateAccount: false,

      patients_view: {
        admin: "admin-patients",
        manager: "manager-patients",
        "company-user": "company-patients",
        "company-owner": "company-patients",
        patient: "home",
      },
    };
  },
  watch: {
    patient_id() {
      this.loadPatient();
    },
  },
  computed: {
    ...mapGetters("patients", ["patients"]),
    ...mapGetters(["user"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("companies", ["companies"]),

    editPatientAccount() {
      return this.user.role === "admin" || this.user.role === "company-owner";
    },
    patient() {
      const item =
        this.patients && this.patients.find((i) => +i.id === +this.patient_id);
      return {
        ...(item || {}),
        age: item?.data?.age,
        comment: item?.data?.comment,
        problem: item?.data?.problem,
        condition: item?.data?.condition,
        review: item?.data?.review,
        visits: this.visitsWithCanRefund(item),
        viewed: item?.data?.visits?.viewed,
        toggleForRefresh: this.toggleForRefresh,
      };
    },
    patient_account() {
      return this.users.find((user) => {
        return user.patient_id === this.patient?.id;
      });
    },
    responsible_user() {
      const user = this.users.find((user) => user.id === this.patient.user_id);
      return user ? user.first_name + " " + user.last_name : "";
    },
    fields() {
      return {
        fio: {
          order: 1,
          label: "ФИО",
          visible: true,
          rules: this.notEmptyRules,
        },
        age: { order: 2, label: "Возраст", visible: true },
        problem: {
          order: 3,
          label: "Проблема или диагноз",
          component: VTextarea,
          visible: true,
        },
        comment: {
          order: 4,
          label: "Комментарий",
          component: VTextarea,
          visible: true,
        },
        condition: {
          order: 5,
          label: "Психологическое состояние",
          component: VTextarea,
          visible: true,
        },
        review: {
          order: 6,
          component: VTextarea,
          label: "Отзыв по результатам занятий",
          visible: true,
        },
      };
    },
    account_fields() {
      return {
        email: {
          order: 1,
          label: "E-Mail (login)",
          visible: true,
          rules: this.emailRules,
        },
        password: {
          order: 2,
          label: "Пароль",
          visible: true,
          rules: this.notEmptyRules.concat(this.passwordRules),
        },
      };
    },
    userCompany() {
      return this.companies.find(
        (company) => company.id === this.user.company_id
      );
    },
    categorySessionsMap() {
      const resultMap = {};
      this.patient?.visits?.forEach((visit) => {
        // Обработка только visits у которых есть category_id и session_number
        // нужно для обратной совместимости со старыми сессиями (20.06.24)
        if (visit.category_id && visit.session_number) {
          const minimumSessionNumber = resultMap[visit.category_id] ?? Infinity;
          if (visit.session_number < minimumSessionNumber) {
            resultMap[visit.category_id] = visit.session_number;
          }
        }
      });

      return resultMap;
    },
  },
  methods: {
    ...mapActions("patients", ["request_patients", "update_patient"]),
    ...mapActions("products", ["request_products"]),
    ...mapActions("users", ["request_users", "update_user", "create_user"]),
    ...mapActions("modals", ["showModalForm"]),
    ...mapActions("companies", ["request_companies", "update_company"]),
    ...mapActions("categories", ["request_categories"]),

    // Определение возможности возврата средств за сессию
    visitsWithCanRefund(patient) {
      const curDate = new Date();
      const cancellationPeriod = process.env.VUE_APP_SESSION_CANCELATION_PERIOD;

      patient?.data?.visits?.active?.forEach((session) => {
        // Количество минут с момента назначения сессии
        const timeFromAppointment =
          Math.abs(curDate - new Date(session.date)) / (1000 * 60);

        const canRefundOld = session.canRefund || false;

        // Возврат возможен если время с момента назначения сессии
        // меньше cancellationPeriod минут
        // и просмотр сессии на начался
        const canRefundNew =
          !session.viewProgress && timeFromAppointment < cancellationPeriod;

        if (canRefundNew && !this.haveRefundableVisits) {
          this.haveRefundableVisits = true;
        }

        if (canRefundNew !== canRefundOld) {
          session.canRefund = canRefundNew;
        }
      });

      return patient?.data?.visits?.active;
    },
    doNewVisit() {
      //console.log(this.selected);
      this.showModalForm({
        component: SelectProductsForm,
        width: this.$vuetify.breakpoint.smAndUp
          ? "800px"
          : this.$vuetify.breakpoint.width,
        contentClass: "border-radius-30px",
      }).then(([products]) => {
        // Списание средств только после окончания пробного периода
        const isTrialEnded =
          !this.userCompany.custom_data.trial_end_time ||
          new Date() > new Date(this.userCompany.custom_data.trial_end_time);

        const visits = [];
        // Сумма списания за все назначенные сессии
        let totalCost = 0;

        products.forEach((product) => {
          const sessionPrice = isTrialEnded
            ? product.Category.session_price
            : 0;

          totalCost += sessionPrice;

          visits.push({
            id: uuidv4(),
            kinescope_id: product?.kinescope_id,
            product_name:
              product.Category.name +
              " / Сессия " +
              product.session_number +
              " / " +
              product.product_type,
            length: 0,
            date: `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`,
            session_price: sessionPrice, // Сохранение стоимости сессии
            prescriber: this.user.first_name + " " + this.user.last_name,
            category_id: product.category_id,
            session_number: product.session_number,
          });
        });

        const companyBalance = Number(
          this.userCompany.custom_data.balance || 0
        );

        const companyOverdraft = Number(
          this.userCompany.custom_data.overdraft || 0
        );

        const updateCompany = () => {
          this.update_company({
            ...this.userCompany,
            custom_data: {
              ...this.userCompany.custom_data,
              balance: companyBalance - totalCost,
            },
          }).then(() => {
            this.request_companies();
          });
        };

        const updatePatient = () => {
          this.update_patient({
            id: this.patient.id,
            data: {
              ...this.patient.data,
              visits: {
                active: [
                  ...(this.patient?.data?.visits?.active || []),
                  ...visits,
                ],
                viewed: [...(this.patient?.data?.visits?.viewed || [])],
              },
            },
          }).then(() => {
            this.loadPatient();
          });
        };

        if (!isTrialEnded) {
          updatePatient();
        } else {
          if (companyBalance + companyOverdraft >= totalCost) {
            updateCompany();
            updatePatient();
            for (var i in visits) {
              this.createReport(visits[i]);
            }
          } else {
            this.$refs.error_wrapper.showErrorMessage(
              "Ошибка",
              "Недостаточно средств"
            );
          }
        }
      });
    },
    doSave(item) {
      this.update_patient({
        ...item,
        data: {
          ...item.data,
          age: item.age,
          comment: item.comment,
          problem: item.problem,
          condition: item.condition,
          review: item.review,
        },
      }).then(() => this.loadPatient());
    },

    loadPatient() {
      if (this.patient_id) {
        this.request_patients(`id=${this.patient_id}`);
      }
    },

    async createReport(value) {
      try {
        await ApiService.post("/api/reports", {
          company_name: this.userCompany.name,
          income: value.income,
          product_name: value.product_name,
          spent: value.session_price,
          doctor: value.prescriber,
          patient: this.patient.fio,
          visit_id: value.id,
        });
      } catch (error) {
        console.error("Ошибка создания отчета:", error);
      }
    },
    async deleteReport(visit_id) {
      try {
        await ApiService.delete(`/api/reports`, {
          params: { visit_id: visit_id },
        });
      } catch (error) {
        console.error("Ошибка удаления отчета:", error);
      }
    },

    // ACCOUNT METHODS

    async doSaveAccount(item) {
      await this.request_users("all=1");

      const conflictUser = this.users.find((user) => {
        return user.email === item.email;
      });
      if (conflictUser) {
        var message = "Попробуйте выбрать другой E-Mail";
        await this.$refs.error_wrapper.showErrorMessage(
          "Данный E-Mail уже занят",
          message
        );
        return;
      }

      if (this.patient_account) {
        await this.update_user({
          ...this.patient_account,
          email: item.email,
          password: item.password,
        }).then(() => this.loadPatient());
        return;
      }
      if (item.password && item.email)
        await this.create_user({
          email: item.email,
          password: item.password,
          role: "patient",
          patient_id: this.patient.id,
        });
      else
        await this.$refs.error_wrapper.showErrorMessage(
          "Ошибка создания аккаунта",
          "Некорректно введены поля."
        );
    },
    //

    showSessionDialog(item) {
      // Проверка только visits у которых есть category_id и session_number
      // нужно для обратной совместимости с старыми сессиями (20.06.24)
      if (
        item.session_number &&
        item.session_number > this.categorySessionsMap[item.category_id]
      ) {
        this.$refs.error_wrapper.showErrorMessage(
          "Ошибка",
          "Для просмотра данной сессии необходимо сначала ознакомиться с предыдущими. Ваша текущая сессия имеет номер " +
            this.categorySessionsMap[item.category_id]
        );
      } else {
        this.showModalForm({
          title: item.product_name,
          component: SessionForm,
          width: this.$vuetify.breakpoint.smAndUp ? "50%" : "80%",
          item,
          patient_id: this.patient_id,
          saveMethod: (item) => {
            return this.update_patient({
              id: this.patient.id,
              data: {
                ...this.patient.data,
                visits: {
                  ...this.patient?.data?.visits,
                  active: [
                    ...(this.patient?.data?.visits?.active || []).filter(
                      (v) => v.id !== item.id
                    ),
                    {
                      ...item,
                    },
                  ],
                  viewed: [...(this.patient?.data?.visits?.viewed || [])],
                },
              },
            }).then(() => this.loadPatient());
          },
          archiveItem: () => {
            item.complete_reason = "Просмотрено";
            this.archiveItem(item);
          },
        }).then((payload) => {
          console.log(payload);
        });
      }
    },
    archiveItem(item) {
      item.complete_date = `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
      this.update_patient({
        id: this.patient.id,
        data: {
          ...this.patient.data,
          visits: {
            active: [
              ...(this.patient?.data?.visits.active.filter(
                (v) => v.id !== item.id
              ) || []),
            ],
            viewed: [...(this.patient?.data?.visits.viewed || []), item],
          },
        },
      });
    },
    doDeleteItem(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены что хотите удалить ${item.product_name}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        item.complete_reason = "Удалено";
        this.archiveItem(item);
        this.loadPatient();
      });
    },
    changeResponsibleUser() {
      this.update_patient({
        id: this.patient.id,
        user_id: this.selected[0].id,
      }).then(() => this.loadPatient());

      this.changeResponsible = false;
      this.snackbarText = `Ответственный изменен на ${this.responsible_user}`;
      this.snackbar = true;
    },
    generateUrl(item) {
      const patientDataString = JSON.stringify({
        patient_id: this.patient_id,
        session_id: item.id,
      });

      const encryptedPatientData = CryptoJS.AES.encrypt(
        patientDataString,
        process.env.VUE_APP_CRYPT_SECRET_KEY
      ).toString();

      const base64PatienData = btoa(encryptedPatientData);

      navigator.clipboard.writeText(
        `${process.env.VUE_APP_BASE_PATH}/session-by-url/${base64PatienData}`
      );

      this.snackbarText = `Ссылка скопирована в буфер обмена`;
      this.snackbar = true;
    },

    doRefundItem(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены что хотите отменить назначение ${item.product_name}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        const companyBalance = Number(this.userCompany.custom_data.balance);
        // Возвращение списанных средств
        this.update_company({
          ...this.userCompany,
          custom_data: {
            ...this.userCompany.custom_data,
            balance: companyBalance + item.session_price,
          },
        }).then(() => {
          this.request_companies();
        });
        // Полное удаление сессии
        this.update_patient({
          id: this.patient.id,
          data: {
            ...this.patient.data,
            visits: {
              ...this.patient?.data?.visits,
              active: [
                ...(this.patient?.data?.visits?.active || []).filter(
                  (v) => v.id !== item.id
                ),
              ],
              viewed: [...(this.patient?.data?.visits?.viewed || [])],
            },
          },
        });
        this.deleteReport(item.id);
      });
    },
    doChangePassword() {
      this.showModalForm({
        component: PasswordResetForm,
      }).then((newPassword) => {
        console.log(newPassword);
        console.log("password reset");
        this.update_user({
          ...this.patient_account,
          password: newPassword,
        }).then(() => {
          this.snackbarText = `Пароль изменен`;
          this.snackbar = true;
          this.loadPatient();
        });
      });
    },
    doChangeEmail() {
      this.showModalForm({
        component: EmailResetForm,
      }).then((newEmail) => {
        console.log(newEmail);
        console.log("email reset");
        console.log(this.patient_account);
        this.update_user({
          ...this.patient_account,
          email: newEmail,
        }).then(() => {
          this.snackbarText = `Email изменен`;
          this.snackbar = true;
          this.loadPatient();
        });
      });
    },
  },
  async beforeMount() {
    this.request_users();
    this.request_categories();
    this.request_companies();

    this.loadPatient();

    this.updateRefundPropInterval = setInterval(() => {
      this.toggleForRefresh = !this.toggleForRefresh;

      if (this.haveRefundableVisits) {
        this.loadPatient();
        this.haveRefundableVisits = false;
      }
    }, 10000);
  },
  mounted() {
    // load products for SelectProductsForm
    this.request_products("sort=category_id");
  },
  beforeDestroy() {
    clearInterval(this.updateRefundPropInterval);
  },
};
</script>

<style scoped lang="scss">
.toolbar-content {
  background-color: #f0f8f6;
  color: #d86568 !important;
}

@import "~vuetify/src/styles/styles.sass";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .views-history {
    max-width: 100px;
  }
}
</style>
