var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Карточка пациента "),_c('router-link',{staticClass:"text-body-1 text-sm-h6",attrs:{"to":{
        name: this.patients_view[_vm.user.role],
      }}},[_vm._v("Все пациенты")])],1),(_vm.show_company)?_c('div',{staticClass:"text-h6"},[_vm._v("Компания: "+_vm._s(_vm.show_company))]):_vm._e(),(_vm.patient)?_c('v-card',{staticClass:"transparent",attrs:{"elevation":"0"}},[_c('v-card',[_c('v-card-subtitle',[_vm._v(" Последнее обновление: "+_vm._s(new Date(_vm.patient.updatedAt).toLocaleDateString())+" ")]),_c('v-card-text',[(_vm.patient.id)?_c('property-form',{attrs:{"value":_vm.patient,"fields":_vm.fields,"no_editing":_vm.no_editing},on:{"input":_vm.doSave}}):_vm._e()],1)],1),(_vm.user.role !== 'manager')?_c('v-card',{staticClass:"my-5 d-flex justify-space-between align-center pa-4"},[_c('div',{staticClass:"text-h6"},[_vm._v("Ответственный: "+_vm._s(_vm.responsible_user))]),_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#d86568"},attrs:{"elevation":"2","icon":""},on:{"click":function($event){_vm.changeResponsible = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1):_vm._e(),(this.editPatientAccount || this.patient.user_id === this.user.id)?_c('v-card',{staticClass:"my-5 d-flex justify-space-between align-center pa-4"},[_c('v-container',[_c('v-row',{staticClass:"justify-space-between"},[_c('div',{staticClass:"text-body-1 text-sm-h5 black--text"},[_vm._v(" "+_vm._s(`Заведен аккаунт: ${ this.patient_account?.email ? this.patient_account.email : "Нет" }`)+" ")]),(this.patient_account?.email)?_c('div',[(_vm.user.role === 'admin')?_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#d86568"},attrs:{"rounded":"","elevation":"0"},on:{"click":_vm.doChangeEmail}},[_vm._v("Изменить почту")]):_vm._e(),_c('v-btn',{staticClass:"white--text ml-2",staticStyle:{"background-color":"#d86568"},attrs:{"rounded":"","elevation":"0"},on:{"click":_vm.doChangePassword}},[_vm._v("Изменить пароль")])],1):_vm._e(),(
              !this.patient_account?.email &&
              (_vm.user.role === 'admin' ||
                _vm.user.role === 'company-owner' ||
                _vm.user.role === 'company-user') &&
              !_vm.canCreateAccount
            )?_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#d86568"},attrs:{"elevation":"2"},on:{"click":function($event){_vm.canCreateAccount = true}}},[_vm._v("Создать")]):_vm._e()],1),(_vm.canCreateAccount)?_c('v-row',[_c('v-card-text',[_c('property-form',{attrs:{"value":{},"fields":_vm.account_fields,"no_editing":_vm.no_editing},on:{"input":_vm.doSaveAccount}})],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-card',{staticClass:"my-5"},[_c('v-card-text',[_c('v-card-actions',{staticClass:"px-0"},[_c('div',{staticClass:"views-history text-body-1 text-sm-h5 black--text"},[_vm._v(" Сессии ")]),_c('v-spacer'),_c('v-card',{staticClass:"d-flex align-center",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"},[_vm._v(" 4 сессии ")])],1),_c('v-spacer'),_c('v-card',{staticClass:"d-flex align-center",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"},[_vm._v(" "+_vm._s((_vm.patient.visits || []).length)+" визитов ")])],1),_c('v-spacer'),(
              _vm.user.role === 'company-owner' || _vm.user.role === 'company-user'
            )?_c('v-btn',{staticStyle:{"background-color":"#d86568"},attrs:{"elevation":"2","icon":""},on:{"click":_vm.doNewVisit}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('object-grid',{staticClass:"mb-7",attrs:{"content_type":_vm.content_type,"value":_vm.patient.visits || [],"hide-toolbar":true,"show-actions":{
            edit: this.user.role !== 'manager' ? true : false,
            url: this.user.role !== 'manager' ? true : false,
            delete: this.user.role !== 'manager' ? true : false,
            refund: this.user.role !== 'manager' ? true : false,
          },"field-defs":[
            { text: 'Сессия', value: 'product_name' },
            { text: 'Просмотрено', value: 'viewProgressFormatted' },
            { text: 'Дата назначения', value: 'date' },
            { text: 'Назначивший', value: 'prescriber' },
          ]},on:{"doEditItem":_vm.showSessionDialog,"generateUrl":_vm.generateUrl,"doDeleteItem":_vm.doDeleteItem,"doRefundItem":_vm.doRefundItem}}),_c('div',{staticClass:"views-history text-body-1 text-sm-h5 black--text"},[_vm._v(" История сессий ")]),_c('object-grid',{attrs:{"content_type":_vm.content_type,"value":_vm.patient.viewed || [],"hide-toolbar":true,"show-actions":{
            edit: false,
            url: false,
            delete: false,
          },"field-defs":[
            { text: 'Сессия', value: 'product_name' },
            { text: 'Дата назначения', value: 'date' },
            { text: 'Назначивший', value: 'prescriber' },
            { text: 'Статус', value: 'complete_reason' },
            { text: 'Просмотрено', value: 'viewProgressFormatted' },
            { text: 'Дата окончания', value: 'complete_date' },
          ]},on:{"doEditItem":_vm.showSessionDialog,"generateUrl":_vm.generateUrl,"doDeleteItem":_vm.doDeleteItem}})],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":_vm.$vuetify.breakpoint.smAndUp ? '50%' : '100%'},model:{value:(_vm.changeResponsible),callback:function ($$v) {_vm.changeResponsible=$$v},expression:"changeResponsible"}},[_c('v-card',{staticClass:"overflow-hidden rounded-xl",attrs:{"color":"#f0f8f6"}},[_c('div',{staticClass:"text-right ma-3"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.changeResponsible = false}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"pb-5",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center text-h4",attrs:{"cols":"16"}},[_c('div',[_vm._v("Изменить ответственного")])])],1),_c('v-card-text',[_c('object-grid',{attrs:{"value":_vm.users.filter(
              (user) =>
                user.role === 'company-user' || user.role === 'company-owner'
            ),"field-defs":[
            {
              text: 'Выберите пользователя',
              value: 'full_name',
            },
          ],"hide-toolbar":true,"selectable":true,"single-select":true},on:{"itemSelected":(v) => (this.selected = v)}})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#d86568","width":"150px","height":"50px","border-radius":"50px"},attrs:{"disabled":!_vm.selected},on:{"click":function($event){return _vm.changeResponsibleUser()}}},[_vm._v(" Назначить ")])],1)],1)],1),_c('v-snackbar',{staticClass:"snackbar",attrs:{"timeout":_vm.timeout,"color":"primary","outlined":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('error-message-wrapper',{ref:"error_wrapper"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }