<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Пациенты
      <v-btn
        color="#d86568"
        large
        rounded
        class="pa-5 px-sm-10 white--text"
        @click="createPatient"
      >
        <v-icon left>mdi-plus</v-icon>
        Добавить
      </v-btn>
    </div>
    <object-grid-advanced
      title="Пациенты"
      :value="patients_with_responsible_info"
      :show-actions="{ edit: true, delete: true }"
      :field-defs="[
        { text: 'Пациент', value: 'fio', width: '45%' },
        { text: 'Ответственный', value: 'user_full_name', width: '43%' },
      ]"
      :hide-toolbar="false"
      @itemSelected="(v) => (this.selected = v)"
      @doEditItem="
        (item) =>
          $router.push({
            name: 'company-patient',
            params: {
              patient_id: String(item.id),
            },
          })
      "
      @doDeleteItem="deletePatient"
      instance-id="company_patientSearch"
    />
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>

<script>
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import { mapActions, mapGetters } from "vuex";
import NewPatientForm from "@/components/NewPatientForm.vue";
import PromptForm from "@/components/PromptForm.vue";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";

export default {
  name: "CompanyPatientsView",
  components: { ErrorMessageWrapper, ObjectGridAdvanced },
  data() {
    return {
      vision_ban: false,
      selected: [],
    };
  },
  computed: {
    ...mapGetters("patients", ["patients"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters(["user"]),

    title() {
      return this.modalFormComponent?.title;
    },
    desc() {
      return this.modalFormComponent?.desc;
    },
    patients_with_responsible_info() {
      return this.patients.map((patient) => {
        const user = this.users.find((user) => user.id === patient.user_id);
        const user_full_name = user && user.full_name ? user.full_name : "";
        return {
          ...patient,
          user_full_name: user_full_name,
        };
      });
    },
  },
  methods: {
    ...mapActions("patients", [
      "request_patients",
      "create_patient",
      "delete_patient",
    ]),
    ...mapActions("users", ["create_user", "request_users", "delete_user"]),
    ...mapActions("modals", ["showModalForm"]),
    deletePatient(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены что хотите удалить пациента ${item.fio}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        const account = this.users.find((user) => user.patient_id === item.id);
        if (account) this.delete_user(account);
        this.delete_patient(item).then(() => {
          this.request_patients(
            this.vision_ban ? `user_id=${this.user.id}` : ""
          );
        });
      });
    },
    createPatient() {
      if (this.user.Company.custom_data.trial === "Да") {
        if (this.patients.length >= this.user.Company.max_patients)
          return this.$refs.error_wrapper.showErrorMessage(
            "Ошибка",
            `Вы достигли лимита пациентов (${this.user.Company.max_patients}) в пробном периоде.`
          );
      }
      this.showModalForm({
        title: "Новый пациент",
        component: NewPatientForm,
      }).then((item) => {
        this.create_patient({ ...item })
          .then((patient) => {
            this.create_user({
              email: item.email,
              password: item.password,
              role: "patient",
              patient_id: patient.id,
            });
            this.request_patients(
              this.vision_ban ? `user_id=${this.user.id}` : ""
            );
          })
          .catch(() => {
            return this.$refs.error_wrapper.showErrorMessage(
              "Ошибка",
              `Вы достигли лимита пациентов (${this.user.Company.max_patients}) в пробном периоде.`
            );
          });
      });
    },
  },
  beforeMount() {
    this.vision_ban = this.user.feature_bans.includes("ONLY_OWNED");
    this.request_patients(this.vision_ban ? `user_id=${this.user.id}` : "");
    this.request_users("all=1");
  },
};
</script>

<style scoped></style>
