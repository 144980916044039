var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Компании "),_c('v-btn',{staticClass:"pa-5 px-sm-10 white--text",attrs:{"color":"#d86568","large":"","rounded":""},on:{"click":_vm.addNewCompany}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1)],1),_c('v-chip-group',{attrs:{"active-class":"primary accent-4 white--text","column":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Активные ")],1),_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-archive")]),_vm._v(" Архивные ")],1)],1),_c('object-grid-advanced',{attrs:{"value":this.companies.map((company) => ({
        id: company.id,
        name: company.name,
        city: company.city,
        manager_id: company.manager_id,
        max_patients: company.max_patients,
        ...company.custom_data,
      })),"field-defs":[
      { text: 'Компания', value: 'name', width: '10%' },
      { text: 'ИНН', value: 'inn', width: '10%' },
      { text: 'ОГРН', value: 'ogrn', width: '10%' },
      { text: 'Пробный период', value: 'trial', width: '10%' },
      { text: 'Баланс', value: 'balance', width: '8%' },
      {
        text: 'Конец пробного периода',
        value: 'trial_end_time',
        width: '10%',
      },
      { text: 'E-mail', value: 'email', width: '10%' },
      { text: 'Телефон', value: 'phone', width: '10%' },
      { text: 'Город', value: 'city', width: '10%' },
    ],"show-actions":{
      edit: !_vm.selection,
      delete: !_vm.selection,
      restore: _vm.selection,
    },"instanceId":`companiesSearch_${_vm.selection ? 'archive' : 'active'}`},on:{"doEditItem":_vm.editCompany,"doDeleteItem":_vm.deleteCompany,"doRestoreItem":_vm.restoreCompany}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }